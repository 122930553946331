<!-- 咨询详情 -->
<template>
  <div>
    <div class="container">
      <el-card>
        <div>
          <div class="title">
            {{ jsonData.noticeTitle }}
          </div>
          <div style="text-align: right; margin: 20px 0">
            {{ jsonData.createTime.slice(0, 10) }}
          </div>
        </div>
        <div v-html="jsonData.noticeContent" style="color: #999"></div>
      </el-card>
    </div>
       <!-- 底部     -->
    <!-- <elFooter></elFooter> -->
  </div>
</template>

<script>
import { noticeId } from '@/api/login'
import elFooter from '@/components/footer'

export default {
  // components: { elFooter },

  data() {
    return {
      jsonData: {},
    }
  },
  created() {
    console.log(this.$route.query.id)
    noticeId(this.$route.query.id).then((res) => {
      console.log(res)
      this.jsonData = res.data
    })
  },
}
</script>
<style lang='scss' scoped>
.title {
  border-bottom: 1px solid #fcb110;
  line-height: 50px;
  font-size: 20px;
  font-weight: 600;
}
</style>